<template>
  <div>
      <div 
        v-if="filterVisible" 
        style="min-height: 100px"
        class="d-flex align-center px-2 mobile-wrap"
      >
        <div style="width: 200px">
          <DateTimePicker 
            v-model="localStartDate"
            filled
            dense
            hide-details="auto"
            label="Inizio"
            :date-picker-width="null"
            :loading="loading"
            :show-time="false"
            @input="$emit('update:start-date', $event)"
          ></DateTimePicker>
        </div>
        <div style="width: 200px" class="ml-3">
          <DateTimePicker 
            v-model="localEndDate"
            filled
            dense
            hide-details="auto"
            :show-time="false"
            label="Fine"
            :date-picker-width="null"
            :loading="loading"
            @input="$emit('update:end-date', $event)"
          ></DateTimePicker>
        </div>
        <v-chip 
          color="primary"
          class="ml-7"
          @click="setToday"
          :disabled="loading"
        >Oggi</v-chip>
        <v-chip 
          color="primary"
          class="ml-1"
          @click="setYesterday"
          :disabled="loading"
        >Ieri</v-chip>
        <v-chip
          class="ml-1"
          color="primary"
          @click="setThisMonth"
          :disabled="loading"
        >Questo mese</v-chip>
        <v-chip
          class="ml-1"
          color="primary"
          @click="setLastMonth"
          :disabled="loading"
        >Lo scorso mese</v-chip>
        <v-chip 
          class="ml-1"
          color="primary"
          @click="setThisYear"
          :disabled="loading"
        >Quest'anno</v-chip>
        <v-autocomplete
          v-model="localWithImports"
          :items="[
            {text: 'In contanti', value: 'cash'},
            {text: 'Elettronico', value: 'card'},
            {text: 'Assegni', value: 'check'},
            {text: 'Altri metodi', value: 'other'},
          ]"
          class="ml-2"
          hide-details="auto"
          style="max-width: 300px"
          multiple
          small-chips
          deletable-chips
          dense
          filled
          chips
          label="Con importo"
          @input="$emit('update:with-imports', $event)"
        ></v-autocomplete>
        <CustomersAutocomplete
          v-model="localCustomer"
          return-object
          :clearable="true"
          @input="$emit('update:customer', $event)"
          prepend-icon="mdi-account"
          :auto-detect-new-customer="false"
        ></CustomersAutocomplete>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          text
          @click="loadPaymentTransactions"
          :loading="loading"
        >Calcola</v-btn>
      </div>
    <v-divider></v-divider>
    <TypeDataTable
      v-model="localValue"
      :loading="loading"
      loading-text="Caricamento storico ..."
      :headers="headers"
      :items="filteredPaymentTransactions"
      :translator="translator"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :height="dataTableHeight"
      :show-actions="false"
      :show-select="!!showDialogRowClicked ? false : true"
      @update:page="loadPaymentTransactions"
      @input="!!showDialogRowClicked ? rowClicked($event) : $emit('input',$event)"
      :default-data-table-header-select="false"
      :single-select="singleSelect"
      :rowPerPageOptions ="[5,20,50,100]"
    >
      <template  v-slot:custom-customer="{ item }">
        <v-chip v-if="item.bill.type==='service' && !!item.info.customers">
          {{item.info.customers.map(customer => !!customer.firstname && !!customer.lastname ? `${customer.firstname} ${customer.lastname}` : undefined).filter(el => !!el).join(',')}}
        </v-chip>
        <v-chip v-else-if="item.bill.type==='item'">
          {{item.info.reservation.reservation.reservationName}}
        </v-chip>
        <v-chip v-else-if="item.bill.type === 'occasional'">Cliente Occasionale</v-chip>
      </template>

      <template v-slot:custom-date="{ item }">
        <DateTimeFormattor
          :value="new Date(item.date)"
        ></DateTimeFormattor>
      </template>
       <template v-slot:custom-checkPaid="{ item }">
            <v-chip v-if="!!item.checkPaid && Number(item.checkPaid) > 0" color="#f39808">{{item.checkPaid + ' €'}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-cashPaid="{ item }">
            <v-chip v-if="!!item.cashPaid && Number(item.cashPaid) > 0" color="#f39808">{{item.cashPaid + ' €'}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-cardPaid="{ item }">
            <v-chip v-if="!!item.cardPaid && Number(item.cardPaid) > 0" color="#f39808">{{item.cardPaid + ' €'}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-otherPaid="{ item }">
            <v-chip v-if="!!item.otherPaid && Number(item.otherPaid) > 0" color="#f39808">{{item.otherPaid + ' €'}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>
          <template v-slot:custom-customerCardPaid="{ item }">
            <v-chip v-if="!!item.customerCardPaid && Number(item.customerCardPaid) > 0" color="#f39808">{{item.customerCardPaid + ' €'}}</v-chip>
            <v-chip v-else>{{'0 €'}}</v-chip>
          </template>

      <template v-slot:custom-totalValue="{ item }">
        <v-chip color="#8fce00">{{calculateTotal(item)}}</v-chip>
        
      </template>
      <template v-slot:custom-providedServices="{ item }">
        <div 
          v-if="!item.bill"
        >
          {{item.bill}}
        </div>
        <div 
          v-else-if="item.bill.type==='service'"
          v-html="calculateProvidedServices(item)"
        >
        </div>
        <div v-else-if="item.bill.type==='item'">
          Prestazioni ristorante
        </div>
      </template>
    </TypeDataTable>
    <PaymentTransactionDetailDialog
      v-model="showPayemntTransactionDetail"
      :rowSelected="rowSelected"
      @close="showPayemntTransactionDetail = false"
    ></PaymentTransactionDetailDialog>
  </div>
</template>

<script>
import CustomersAutocomplete from "@/components/common/CustomersAutocomplete.vue";
import paymentTransactionsService from '@/services/paymentTransactions/paymentTransactions.service.js'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import DateTimeFormattor from '../../../common/DateTimeFormattor.vue'
import StandardDialog from "@/components/common/StandardDialog.vue";
import PaymentTransactionDetailDialog from "./PaymentTransactionDetailDialog.vue"
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import Vue from 'vue'

export default {
  name: "PaymentTransactionsTable",
  components: {
    TypeDataTable,
    DateTimeFormattor,
    DateTimePicker,
    StandardDialog,
    PaymentTransactionDetailDialog,
    CustomersAutocomplete
  },
  data: function() {

    return {
      paymentTransactions: [],
      localValue: undefined,
      loading: false,
      headers: [],
      page: 1,
      rowPerPage: 5,
      totalPages: 0,

      localStartDate: undefined,
      localEndDate: undefined,
      localWithImports: [],
      showPayemntTransactionDetail: false,
      rowSelected: undefined,
      localCustomer: undefined,
    }
  },
  mounted: function() {
    this.localStartDate = this.startDate
    this.localEndDate = this.endDate
    this.localWithImports = this.withImports
    this.localCustomer = this.customer

    this.loadHeaders()
    this.loadPaymentTransactions()

    if(!!this.bus) {
      let self = this
      this.bus.$on('reload', () => {
        self.loadPaymentTransactions()
      })
    }
  },
  props: {
    height: {
    },
    value: {
    },
    startDate: {
    },
    endDate: {
    },
    customer: {
    },
    listBills: {
      type: Boolean,
      default: true,
    },
    noInvoice: {
      type: Boolean,
      default: false
    },
    filterVisible: {
      type: Boolean,
      default: true
    },
    withImports: {
      type: Array,
      default: () => {
        return []
      }
    },
    singleSelect: {
      type: Boolean,
      default: true
    },
    bus: {
      type: Object,
      default: () => {
        return new Vue()
      }
    },
    showDialogRowClicked: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    loadPaymentTransactions() {
      this.loading = true
      
      if(!!this.localStartDate){
        this.localStartDate = new Date(this.localStartDate)
        this.localStartDate.setHours(0)
        this.localStartDate.setMinutes(0)
      }
      if(!!this.localEndDate){
        this.localEndDate = new Date(this.localEndDate)
        this.localEndDate.setHours(23)
        this.localEndDate.setMinutes(59)
      }

      paymentTransactionsService.history(this.page || 1, this.rowPerPage, {
        startDate: this.localStartDate,
        endDate: this.localEndDate,
        withImports: this.localWithImports,
        customer: this.localCustomer,
        listBills: this.listBills,
        noInvoice: this.noInvoice
      }).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.paymentTransactions = results.rows
        this.loading = false
      })
    },
    loadHeaders() {
      paymentTransactionsService.fields().then((headers) => {
        this.headers = headers
      })
    },
    translator(key, value) {
      const documentTypeMapping = {
        'receipt': 'Ricevuta',
        'invoice': 'Fattura',
        'payLater': 'Paga successivamente'
      }

      if(key == 'documentType') {
        return documentTypeMapping[value]
      }
    },
    setToday() {
      this.localStartDate = new Date()
      this.localEndDate = new Date()
    },
    setYesterday() {
      const yesterday = new Date(new Date().getTime() - 24 * 60 * 60000)
      this.localStartDate = yesterday
      this.localEndDate = yesterday
    },
    setThisMonth() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.localStartDate = firstDay

      var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.localEndDate = lastDay
    },
    setLastMonth() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.localStartDate = firstDay

      var lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.localEndDate = lastDay
    },
    setThisYear() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), 0, 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.localStartDate = firstDay

      var lastDay = new Date(today.getFullYear() + 1, 0, 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.localEndDate = lastDay
    },
    sumCurrencyAmounts(...amounts) {
      if(!amounts) {
        return 0
      } else if(amounts.length == 0) {
        return 0
      } else {
        let accumulator = 0
        for(let i = 0; i < amounts.length; i += 1) {
          if(!!amounts[i]) {
            accumulator = Number(Number(Number(Number(accumulator).toFixed(2)) + Number(Number(amounts[i]).toFixed(2))).toFixed(2))
          }
        }
        return accumulator
      }
    },
    calculateTotal(item) {
      let totalValue = this.sumCurrencyAmounts(
        item.cashPaid,
        item.checkPaid,
        item.cardPaid,
        item.otherPaid,
        item.notPaid
      )
      return `${totalValue} €`
    },
    calculateProvidedServices(item) {
      let services = ''
      if(!!item.info && !!item.info.items && item.info.items.length > 0) {
        services = item.info.items.map(el => {
          let final = ''
          if(!!el.name) {
            final += el.name
          } else if(!!el.description) {
            final += el.description
          }

          if(!!el.quantity) {
            final += ', qt. ' + el.quantity
          }

          if(!!el.operators) {
            final += ' (' + el.operators.map(op => op.firstname + ' ' + op.lastname ).join(', ') + ')'
          }
          return final
        }).filter(el => !!el).join('<br />')
      }
      return services
    },
    rowClicked(row) {
      if(!!row[0]){
        this.localValue = []
        row[0].itemsSelectedDialog = []
        row[0].servicesSelectedDialog = []
        row[0].createdAt = new Date(row[0].createdAt)
        row[0].totalValue = this.calculateTotal(row[0])
        row[0].info.items.map((item) => {
          if(item._type == 'item')
            row[0].itemsSelectedDialog.push(item)
          else if (item._type == 'service')
            row[0].servicesSelectedDialog.push(item)
        })
        this.rowSelected = row[0]
        this.showPayemntTransactionDetail = true
      }
    },

  },
  computed: {
    filteredPaymentTransactions() {
      return this.paymentTransactions
    },
    dataTableHeight() {
      if(this.filterVisible) {
        return 'calc(' + this.height + ' - 100px)'
      } else {
        return this.height
      }
    }
  },
  watch: {
    startDate(newVal) {
      this.localStartDate = newVal
    },
    endDate(newVal) {
      this.localEndDate = newVal
    },
    withImports(newVal) {
      this.localWithImports = newVal
    },
    customer(newVal) {
      this.localCustomer = newVal
    },
    rowPerPage(newVal){
      if(!!newVal)
        this.loadPaymentTransactions()
    }
  }
}
</script>

<style>
  @media screen and (max-width: 960px){    
    .mobile-wrap {
      flex-wrap: wrap;
    }
  }
</style>