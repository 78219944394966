<template>

  <StandardDialog
    v-model="localValue"
    persistent
    title="Dettaglio Transizione"
    dialog-height="calc(90vh-1)"
    dialog-width="80vw"
  >
  <div v-if="!!rowSelected">
    <v-container>
      <v-row>
        <v-col>
          <v-text-field
            :value="fullnameDetailDialog"
            filled
            dense
            hide-details="auto"
            label="Cliente"
            diasbled
            readonly
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-if="rowSelected.documentType == 'receipt'"
            value = "Ricevuta"
            filled
            readonly
            dense
            hide-details="auto"
            label="Tipo Documento"
          ></v-text-field>
          <v-text-field
            v-else-if="rowSelected.documentType == 'invoice'"
            value = "Fattura"
            filled
            readonly
            dense
            hide-details="auto"
            label="Tipo Documento"
          ></v-text-field>
          <v-text-field
            v-else-if="rowSelected.documentType == 'payLater'"
            value = "Paga Successivamente"
            filled
            dense
            readonly
            hide-details="auto"
            label="Tipo Documento"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
         <v-col cols="3" v-if="!!rowSelected.cashPaid">
          <v-text-field
            :value="rowSelected.cashPaid + ' €'"
            filled
            dense
            readonly
            hide-details="auto"
            label="Contanti"
          ></v-text-field>
        </v-col>
        <v-col v-else cols="3">
          <v-text-field
            value = "0 €"
            filled
            dense
            readonly
            hide-details="auto"
            label="Contanti"
          ></v-text-field>
        </v-col>
        <v-col cols="3" v-if="!!rowSelected.checkPaid">
          <v-text-field
            :value="rowSelected.checkPaid + ' €'"
            filled
            dense
            readonly
            hide-details="auto"
            label="Assegno"
          ></v-text-field>
        </v-col>
        <v-col v-else cols="3">
          <v-text-field
            value = "0 €"
            filled
            readonly
            dense
            hide-details="auto"
            label="Assegno"
          ></v-text-field>
        </v-col>
        <v-col cols="3" v-if="!!rowSelected.cardPaid">
          <v-text-field
            :value="rowSelected.cardPaid + ' €'"
            filled
            dense
            readonly
            hide-details="auto"
            label="Carta"
          ></v-text-field>
        </v-col>
        <v-col v-else cols="3">
          <v-text-field
            value = "0 €"
            filled
            readonly
            dense
            hide-details="auto"
            label="Carta"
          ></v-text-field>
        </v-col>
        <v-col cols="3" v-if="!!rowSelected.otherPaid">
          <v-text-field
            :value="rowSelected.otherPaid + ' €'"
            filled
            dense
            readonly
            hide-details="auto"
            label="Altri Metodi"
          ></v-text-field>
        </v-col>
        <v-col v-else cols="3">
          <v-text-field
            value = "0 €"
            filled
            readonly
            dense
            hide-details="auto"
            label="Altri Metodi"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
           <v-text-field
            :value="rowSelected.totalForItems + ' €'"
            filled
            dense
            readonly
            hide-details="auto"
            label="Totale Prodotti"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :value="rowSelected.totalForServices + ' €'"
            filled
            dense
            readonly
            hide-details="auto"
            label="Totale Servizi"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="rowSelected.totalValue"
            filled
            dense
            readonly
            hide-details="auto"
            label="Totale"
          ></v-text-field>
        </v-col>
        <v-col>
          <DatePicker
            v-model="rowSelected.createdAt"
            readonly
            label = "Data"
          ></DatePicker>
        </v-col>
      </v-row>  
    </v-container>
        <v-alert dense>
          <div class="font-italic font-weight-light mt-2">Lista Prodotti</div>
        </v-alert>
        <TypeDataTable
          :show-select="false"
          item-key="id"
          fixed-header
          :show-actions="false"
          :headers="headersItems"
          :items = "rowSelected.itemsSelectedDialog"
        >
        
        </TypeDataTable>
        <v-alert dense>
          <div class="font-italic font-weight-light">Lista Servizi</div>
        </v-alert>
        <TypeDataTable
          :show-select="false"
          item-key="id"
          fixed-header
          :show-actions="false"
          :headers="headersServices"
          :items = "rowSelected.servicesSelectedDialog"
        >
          <template v-slot:custom-operators="{ item }">
            <ul>
              <li v-for="value in item.operators" :key="value.id">
                {{ value.firstname }} {{value.lastname}}
              </li>
            </ul>  
          </template>
        </TypeDataTable>
      </div>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close">Chiudi</v-btn>
      </template>
    </StandardDialog>
  
</template>

<script>

import StandardDialog from "@/components/common/StandardDialog.vue"
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import DatePicker from '@/components/common/DatePickerCommon.vue'

export default {
  name: 'PaymentTransactionDetailDialog',
  components: {
    DatePicker,
    StandardDialog,
    TypeDataTable,
  },
  data: function(){
    return {
      localValue: false,
      headersItems: [ 
        { text: 'Descrizione', value: 'description' },
        { text: 'Quantità', value: 'quantity' },
        { text: 'Prezzo Di Vendita', value: 'priceSelling', type: 'price' },
      ],
      headersServices: [
        { text: 'Descrizione', value: 'name' },
        { text: 'Quantità', value: 'quantity' },
        { text: 'Prezzo Di Vendita', value: 'price', type: 'price' },
        { text: "Operatori", value: "operators",type: "custom"},
      ]
    }
  },
  props: {
    value: {
      type: Boolean
    },
    rowSelected: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    fullnameDetailDialog() {
      if(!!this.rowSelected)
        return String(this.rowSelected.info.customers[0].firstname + ' ' + this.rowSelected.info.customers[0].lastname)
    }
  },
  watch:{
    value(newVal){
      this.localValue = newVal
    },
  },
  methods:{
    close(){
      this.$emit('close')
    }
  }
}
</script>